import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import {BrowserRouter, Route} from "react-router-dom";
import ApplicationBar from "./ApplicationBar";
import Homepage from "./Homepage";
import Footer from "./Footer";
import EntitiesRoutes from "./EntitiesRoutes";
import _Users from "./Entities/_Users.js";
import Logout from "./Logout";
import SignIn from "./SignIn";
import UserDetailsProvider from "./Contexts/UserDetailsProvider";
import Utilities from "./Components/Utilities";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column',
        minHeight: '100vh'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

export default function App() {
    const classes = useStyles();
    return (
        <BrowserRouter>
            <UserDetailsProvider>
                <SnackbarProvider maxSnack={3}>
                    <div className={classes.root}>
                        <Utilities />
                        <CssBaseline />
                        <ApplicationBar />
                        <div className={classes.content}>
                            <Route exact path="/" component={Homepage} />
                            <Route exact path="/_users" component={_Users} />
                            <Route exact path="/signin" render={(props) => <SignIn {...props} />} />
                            <Route exact path="/logout" render={(props) => <Logout {...props} />} />
                            <EntitiesRoutes />
                        </div>
                        <Footer/>
                    </div>
                </SnackbarProvider>
            </UserDetailsProvider>
        </BrowserRouter>
    );
}
