import React from 'react';
import {Route} from "react-router-dom";
import Coupon from "./Entities/Coupon";
import Magazzino from "./Entities/Magazzino";
import Consegne from "./Entities/Consegne";

export default function EntitiesRoutes() {
    return (
        <React.Fragment>
            <Route exact path="/coupon" component={Coupon} />
            <Route exact path="/magazzino" component={Magazzino} />
            <Route exact path="/consegne" component={Consegne} />
            
        </React.Fragment>
    );
}
