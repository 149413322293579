import React from 'react';
import Typography from '@material-ui/core/Typography';
import {Box} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from './logo.svg';

const useStyles = makeStyles(theme => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
        margin: theme.spacing(-3, -3, 8, -3),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    heroLogo: {
        "& img": {
            display: "block",
            margin: "auto",
            maxWidth: "70vw",
        },
        marginBottom: theme.spacing(8)
    },
    inlineCode: {
        backgroundColor: "rgb(29, 31, 33)",
        color: "rgb(168, 255, 96)",
        padding: "0.2em",
        borderRadius: "0.2em",
        lineHeight: 1,
        fontFamily: "monospace",
        fontSize: "1.2rem",
    },
    code: {
        backgroundColor: "rgb(29, 31, 33)",
        color: "rgb(168, 255, 96)",
        padding: "1em",
        borderRadius: "0.5em",
        lineHeight: 1.5,
        fontFamily: "monospace",
        fontSize: "1.2rem",
    }
}));

export default function Homepage() {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className={classes.heroContent}>
                <Container maxWidth="sm">
                    <div className={classes.heroLogo}><img src={logo} alt="" /></div>
                    <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                        Welcome your app!
                    </Typography>
                    <Typography variant="h5" align="center" color="textSecondary" paragraph>
                        This is your homepage ;-)
                    </Typography>
                </Container>
            </div>
            <Container maxWidth="md">
                <Typography variant="h5" gutterBottom>Accounts</Typography>
                <Box p={2} my={2} bgcolor="primary.main" color="primary.contrastText">
                    <Typography component="div">
                        <p>If you want to <strong>sign in</strong>, you can try the deault accounts:</p>
                        <ul>
                            <li>Administrator: username=<strong>admin</strong>, password=<strong>admin</strong></li>
                            <li>User: username=<strong>user</strong>, password=<strong>user</strong></li>
                        </ul>
                    </Typography>
                </Box>
            </Container>
        </React.Fragment>
    )
}
