var jwt = require('jsonwebtoken');

const JWTTOKEN = 'jwtToken';

export default class jwtTokenManager {
    static read = () => {
        return sessionStorage.getItem(JWTTOKEN) || localStorage.getItem(JWTTOKEN);
    };
    static save = (token, remember) => {
        if(remember) {
            localStorage.setItem(JWTTOKEN, token);
        } else {
            sessionStorage.setItem(JWTTOKEN, token);
        }
        return token;
    };
    static delete = () => {
        sessionStorage.removeItem(JWTTOKEN);
        localStorage.removeItem(JWTTOKEN);
    };
    static decode = () => {
        const jwtToken = sessionStorage.getItem(JWTTOKEN) || localStorage.getItem(JWTTOKEN);
        let user = jwt.decode(jwtToken);
        if(user && user.roles) {
            let roles = user.roles.split(',').map(role => role.replace(/^\s+|\s+$/,''));
            roles.forEach(role => user[`_is${role}`] = true);
        }
        if(user) {
            user.jwtToken = jwtToken;
        }
        return user || {
            jwtToken: '',
            username: '',
            name: '',
            email: '',
            roles: '',
            _isADMIN: false,
            _isUSER: false,
        };
    };
};
