import React, { useState } from 'react'
import UserDetailsContext from "./UserDetailsContext";

const UserDetailsProvider = ({ children }) => {
    /**
     * User details state / controls
     */
    const setUserDetails = ({
        jwtToken,
        username,
        name,
        email,
        roles,
        _isADMIN,
        _isUSER
    }) => {
        updateUserDetails(prevState => {
            const newState = {
                jwtToken,
                username,
                name,
                email,
                roles,
                _isADMIN,
                _isUSER
            };
            return { ...prevState, ...newState }
        })
    };
    const deleteUserDetails = () => {
        updateUserDetails(prevState => {
            const newState = {
                jwtToken: '',
                username: '',
                name: '',
                email: '',
                roles: '',
                _isADMIN: false,
                _isUSER: false,
            };
            return { ...prevState, ...newState }
        })
    };

    const userState = {
        jwtToken: '',
        username: '',
        name: '',
        email: '',
        roles: '',
        _isADMIN: false,
        _isUSER: false,
        setUserDetails,
        deleteUserDetails
    };

    const [userDetails, updateUserDetails] = useState(userState)

    return (
        <UserDetailsContext.Provider value={userDetails}>
            {children}
        </UserDetailsContext.Provider>
    )
};

export default UserDetailsProvider
