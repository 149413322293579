import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import CustomIcons from './CustomIcons';
import ExitIcon from '@material-ui/icons/ExitToApp';
import EntitiesNavigation from './EntitiesNavigation';
import AccountButton from "./AccountButton";
import {Tooltip} from "@material-ui/core";
import UserDetailsContext from "./Contexts/UserDetailsContext";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    logoIcon: {
        display: "flex",
        marginRight: theme.spacing(2),
        "& svg": {
            height: "40px !important",
            width: "40px !important"
        }
    }
}));

export default function ApplicationBar() {
    const classes = useStyles();
    const { _isUSER, _isADMIN } = useContext(UserDetailsContext);
    return (
        <AppBar position="static">
            <Toolbar>
                <Link to="/"><Typography className={classes.logoIcon} color="secondary"><CustomIcons icon="APP_LOGO"/></Typography></Link>
                <Typography variant="h6" className={classes.title}>{process.env.REACT_APP_NAME}</Typography>
                <Button component={Link} to="/" color="inherit">Homepage</Button>
                {_isUSER && <EntitiesNavigation/>}
                {_isADMIN && <Button component={Link} to="/_Users" color="inherit">Users</Button>}
                {!_isUSER && <Button component={Link} to="/signin" color="inherit">Sign in</Button>}
                {_isUSER && <Tooltip title={'Logout'}><IconButton component={Link} to="/logout" color="inherit"><ExitIcon/></IconButton></Tooltip>}
                {_isUSER && <AccountButton />}
            </Toolbar>
        </AppBar>
    );
}
