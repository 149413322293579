import React, {useContext, useEffect} from 'react';
import jwtTokenManager from "./jwtTokenManager";
import { Redirect } from 'react-router'
import { useSnackbar  } from 'notistack';
import UserDetailsContext from "./Contexts/UserDetailsContext";

export default function Logout() {
    const { enqueueSnackbar } = useSnackbar();
    const { deleteUserDetails } = useContext(UserDetailsContext);
    useEffect(() => {
        jwtTokenManager.delete();
        deleteUserDetails();
        enqueueSnackbar('You are now logged out');
    });
    return (<Redirect to="/" />);
};
