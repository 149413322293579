import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/ExpandMore';
import StarIcon from '@material-ui/icons/Star';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    menuIcon: {
        marginRight: theme.spacing(1),
    }
}));

export default function EntitiesNavigation() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <React.Fragment>
            <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >Entities <MoreIcon /></Button>
            <Menu
                id="menu-appbar"
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} component={Link} to="/coupon"><StarIcon className={classes.menuIcon} /> {'Coupon'}</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/magazzino"><StarIcon className={classes.menuIcon} /> {'Magazzino'}</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/consegne"><StarIcon className={classes.menuIcon} /> {'Consegne'}</MenuItem>
                
            </Menu>
        </React.Fragment>
    );
}
