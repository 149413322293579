import React, {useEffect, useState, useContext} from 'react';
import MaterialTable from "material-table";
import { useSnackbar } from 'notistack';
import moment from 'moment-timezone';
import axios from "axios";
import UserDetailsContext from "../Contexts/UserDetailsContext";
import Forbidden from "../Forbidden";

const componentApiPath = 'magazzino';

moment().format(); // avoid unused warning

export default function Magazzino () {
    const { _isUSER } = useContext(UserDetailsContext);
    const { enqueueSnackbar } = useSnackbar();
    const [items, setItems] = useState([]);

    useEffect(() => {
        axios.get(componentApiPath)
            .then(response => {
                setItems(response.data);
                enqueueSnackbar('Items successfully loaded');
            });
    }, [enqueueSnackbar]);

    return !_isUSER ? <Forbidden /> :
        <React.Fragment>
            <MaterialTable
                options={{
                    paging: false
                }}
                columns={[
                    { title: "Codice", field: "codice", type: 'string' },
                    { title: "Nome", field: "nome", type: 'string' },
                    { title: "Brand", field: "brand", type: 'string' },
                    { title: "Descrizione", field: "descrizione", type: 'string' },
                    { title: "Qt", field: "qt", type: 'numeric' },
                ]}
                data={ items }
                title="Magazzino"
                editable={{
                    onRowAdd: newData => new Promise((resolve, reject) => {
                        
                        axios.post(componentApiPath,newData)
                            .then(response => {
                                const item = response.data;
                                items.push(item);
                                setItems(items.slice());
                                enqueueSnackbar('Item created successfully',{ variant: 'success' });
                                resolve();
                            })
                            .catch(error => { reject(); });
                    }),
                    onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                        
                        axios.put(`${componentApiPath}/${oldData._id}`,newData)
                            .then(response => {
                                const item = response.data;
                                const index = items.indexOf(oldData);
                                Object.assign(item,{ password: undefined});
                                items[index] = item;
                                setItems(items.slice());
                                enqueueSnackbar('Item updated successfully',{ variant: 'success' });
                                resolve();
                            })
                            .catch(error => { reject(); });
                    }),
                    onRowDelete: oldData => new Promise((resolve, reject) => {
                        axios.delete(`${componentApiPath}/${oldData._id}`)
                            .then(() => {
                                items.splice(items.indexOf(oldData), 1);
                                setItems(items.slice());
                                enqueueSnackbar('Item deleted successfully',{ variant: 'success' });
                                resolve();
                            })
                            .catch(error => { reject(); });
                    }),
                }}
            />
        </React.Fragment>;
};
