import { createContext } from 'react'

const UserDetailsContext = createContext({
    /**
     * Although it is possible to remove the following I like to keep them here
     * because they help anyone importing UserDetailsContext to understand what properties/functions
     * this particular context has available
     */
    jwtToken: '',
    username: '',
    name: '',
    email: '',
    roles: '',
    _isADMIN: false,
    _isUSER: false,
    /**
     * {Object} userDetails i.e. {[username], [name], [email], [roles], [_isADMIN], [_isUSER]}
     */
    setUserDetails: userDetails => {},
    deleteUserDetails: () => {}
});

export default UserDetailsContext;
