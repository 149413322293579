import React, {useContext} from 'react';
import Button from '@material-ui/core/Button';
import MoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from "react-router-dom";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExitIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from "@material-ui/icons/Settings";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Tooltip} from "@material-ui/core";
import Gravatar from "react-gravatar";
import UserDetailsContext from "./Contexts/UserDetailsContext";
const useStyles = makeStyles(theme => ({
    menuIcon: {
        marginRight: theme.spacing(1),
    },
    gravatar: {
        backgroundColor: theme.palette.common.white,
        borderRadius: "100%",
        fontSize: "0rem",
        overflow: "hidden",
        borderColor: theme.palette.secondary.main,
        borderWidth: "2px",
        borderStyle: "solid",
        boxSizing: "content-box",
    }
}));

export default function AccountButton(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const userDetails = useContext(UserDetailsContext);

    function handleMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <React.Fragment>
            <Tooltip title={userDetails.username}><Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            ><Gravatar email={userDetails.email} size={32} className={classes.gravatar} /><MoreIcon /></Button></Tooltip>
            <Menu
                id="menu-appbar"
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} component={Link} to="/profile"><SettingsIcon className={classes.menuIcon} />Profile settings</MenuItem>
                <MenuItem onClick={handleClose} component={Link} to="/logout"><ExitIcon className={classes.menuIcon} />Logout</MenuItem>
            </Menu>
        </React.Fragment>
    );
}
