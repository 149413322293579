import React, {useEffect, useState, useContext} from 'react';
import MaterialTable from "material-table";
import { useSnackbar } from 'notistack';
import Forbidden from "../Forbidden";
import moment from 'moment-timezone';
import Gravatar from "react-gravatar";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import UserDetailsContext from "../Contexts/UserDetailsContext";

const componentApiPath = '_users';
const useStyles = makeStyles(theme => ({
    gravatar: {
        display: "flex",
        alignItems:"center",
        "& img" : {
            marginRight: theme.spacing(2),
            width: theme.spacing(4),
            height: theme.spacing(4),
            backgroundColor: theme.palette.common.white,
            borderRadius: "100%",
            fontSize: "0rem",
            overflow: "hidden",
        }
    },
}));

moment().format(); // avoid unused warning

export default function _Users () {
    const {_isADMIN} = useContext(UserDetailsContext);
    const { enqueueSnackbar } = useSnackbar();
    const [items, setItems] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        axios.get(componentApiPath)
            .then(response => {
                response.data.map(item => Object.assign(item,{ password: undefined}));
                setItems(response.data);
                enqueueSnackbar('Items successfully loaded');
            });
    }, [enqueueSnackbar]);

    return !_isADMIN ? <Forbidden /> :
        <React.Fragment>
            <MaterialTable
                options={{
                    paging: false
                }}
                columns={[
                    {
                        title: "Username",
                        field: "username",
                        type: 'string',
                        editable: "onAdd",
                        render: (rowData) => <div className={classes.gravatar}><Gravatar email={rowData.email||'-'} size={64} className={classes.gravatar} /><span>{rowData.username}</span></div>
                    },
                    { title: "Password", field: "password", type: 'string', render: () => ("●●●●●●●●●●") },
                    { title: "Full Name", field: "fullName", type: 'string' },
                    { title: "Email", field: "email", type: 'string' },
                    { title: "Roles", field: "roles", type: 'string' },
                    { title: "Active", field: "active", type: 'boolean' },
                ]}
                data={ items }
                title="Users"
                editable={{
                    onRowAdd: newData => new Promise((resolve, reject) => {
                        axios.post(componentApiPath,newData)
                            .then(response => {
                                const item = response.data;
                                Object.assign(item,{ password: undefined});
                                items.push(item);
                                setItems(items.slice());
                                enqueueSnackbar('User cretated successfully',{ variant: 'success' });
                                resolve();
                            })
                            .catch(error => {
                                reject();
                            });
                    }),
                    onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                        axios.put(`${componentApiPath}/${oldData._id}`,newData)
                            .then(response => {
                                const item = response.data;
                                const index = items.indexOf(oldData);
                                Object.assign(item,{ password: undefined});
                                items[index] = item;
                                setItems(items.slice());
                                enqueueSnackbar('User updated successfully',{ variant: 'success' });
                                resolve();
                            })
                            .catch(error => {
                                reject();
                            });
                    }),
                    onRowDelete: oldData => new Promise((resolve, reject) => {
                        axios.delete(`${componentApiPath}/${oldData._id}`)
                            .then(() => {
                                items.splice(items.indexOf(oldData), 1);
                                setItems(items.slice());
                                enqueueSnackbar('User deleted successfully',{ variant: 'success' });
                                resolve();
                            })
                            .catch(error => {
                                reject();
                            });
                    }),
                }}
            />
        </React.Fragment>;
}
