import {useSnackbar} from "notistack";
import {useContext, useEffect} from "react";
import axios from "axios";
import UserDetailsContext from "../Contexts/UserDetailsContext";
import jwtTokenManager from "../jwtTokenManager";

const Utilities = () => {
    const { enqueueSnackbar } = useSnackbar();
    const addAuthorizationHeader = (request) => {
        const jwtToken = jwtTokenManager.read();
        if(!!jwtToken) {
            request.headers.authorization = `Bearer ${jwtToken}`;
        }
        return request
    };
    axios.defaults.baseURL = `${process.env.REACT_APP_SERVER_PROTOCOL}//${process.env.REACT_APP_SERVER_HOST}:${process.env.REACT_APP_SERVER_PORT}/api/`;
    axios.defaults.timeout = 60 * 1000;
    axios.interceptors.request.use(
        request => addAuthorizationHeader(request)
    );
    useEffect(()=> {
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            // Do something with response error
            console.log('axios.interceptors.response: %o',error);
            console.log('axios.interceptors.response: %o',error.response && error.response.data);
            console.log('axios.interceptors.response: %o',error.response && error.response.status);
            console.log('axios.interceptors.response: %o',error.response && error.response.headers);
            enqueueSnackbar(`${error.response && error.response.status && error.response.status === 403 ? 'Authentication' : 'Operation'} error: ${(error.response && error.response.data && error.response.data.error) || error.message}`,{ variant: 'error' });
            return Promise.reject(error);
        });
    },[enqueueSnackbar]);
    const { setUserDetails } = useContext(UserDetailsContext);
    useEffect(()=>{
        setUserDetails(jwtTokenManager.decode());
    },[setUserDetails]);
    return null;
};

export default Utilities;
