import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CustomIcons from './CustomIcons';
import Heart from "@material-ui/icons/Favorite"
import {Tooltip} from "@material-ui/core";
import UserDetailsContext from "./Contexts/UserDetailsContext";

const useStyles = makeStyles(theme => ({
    footerIcon: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    madeWith: {
        display: "flex",
        flex:"1 1 auto"
    }
}));

export default function Footer(props) {
    const classes = useStyles();
    const { username, _isUSER } = useContext(UserDetailsContext);
    return (
        <AppBar position="static" color={"secondary"}>
            <Toolbar>
                <Typography className={classes.madeWith}>
                    BUILT WITH
                    <Tooltip title="LOVE"><Heart className={classes.footerIcon} /></Tooltip>
                    &
                    <Tooltip title="MERLO"><a href="https://github.com/giatro/generator-merlo" style={{color:"inherit", display: "flex"}}><CustomIcons icon="MERLO_LOGO" className={classes.footerIcon} /></a></Tooltip>
                </Typography>
                {_isUSER && <Typography>Logged in as <strong>{username}</strong></Typography>}
            </Toolbar>
        </AppBar>
    );
}
